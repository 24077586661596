<template>
  <div class="d-flex flex-fill fill-height justify-center align-center">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          sm="8"
          offset-sm="2"
          md="6"
          offset-md="3"
          lg="4"
          offset-lg="4"
        >
          <v-card>
            <v-card-title class="justify-center success">
              Reforço Caixa
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between py-4">
                <div>
                  <strong>Data: </strong>
                  {{ new Date() | moment("DD/MM/YYYY") }}
                </div>
                <div>
                  <strong>Hora: </strong> {{ new Date() | moment("hh:mm:ss") }}
                </div>
              </div>
              <div>
                <v-text-field-money
                  v-bind:properties="properties"
                  v-bind:options="options"
                  v-model="value"
                  label="Valor:"
                ></v-text-field-money>
                <v-textarea
                  v-model="description"
                  filled
                  label="Descrição:"
                ></v-textarea>
              </div>
            </v-card-text>
            <v-card-actions class="justify-space-between">
              <v-btn @click="$router.push('/')" text color="error">
                Fechar
              </v-btn>
              <v-btn color="success" @click="send"> Salvar </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row></v-container
    >
  </div>
</template>

<script>
import mixinSnack from "./../_mixins/snackbar";
import { mapGetters } from "vuex";
export default {
  name: "ReinforceBox",
  mixins: [mixinSnack],
  data() {
    return { value: 0, description: "" };
  },
  methods: {
    async send() {
      if (!this.box) {
        this.snackWarning("Não existe caixa aberto!");
        return;
      }
      try {
        const { value, description } = this;
        const {
          data: { reinforce },
        } = await this.$axios.post("/box/reinforce", {
          value,
          description,
          boxId: this.box.id,
        });
        if (reinforce.id) {
          this.snackSuccess("Reforço efetuado com Sucesso!");
          sessionStorage.setItem("transfer", JSON.stringify(reinforce));
          await this.$router.push({ name: "report_reinforce" });
        }
      } catch (e) {
        const {
          response: {
            data: { error },
          },
        } = e;
        error && this.snackError(error);
      }
    },
    clear() {
      this.value = 0;
      this.description = "";
    },
  },
  computed: {
    ...mapGetters(["box"]),
    properties: () => ({ filled: true, prefix: "R$", placeholder: " " }),
    options: () => ({
      locale: "pt-BR",
      length: 11,
      precision: 2,
      empty: null,
    }),
  },
};
</script>

<style scoped></style>
